export const poolData = [
    {
      title: 'DogSwap / MintMe',
      subTitle: 'Stake your Dogswap / MintMe LP tokens and get $BONE rewards!',
      BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
      MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
      poolId: 5,
      poolType: 'Fixed',
      active: 'Yes',
      fee: '0.5',
      lpTokenAddress: '0xCfFF901398cB001D740FFf564D2dcc9Dbd898a11',
      imageTokenA: 'https://dogswap.xyz/images/coins/dogswap.png',
      imageTokenB: 'https://dogswap.xyz/images/coins/mintme.png',
    },
    {
       title: '$BONE / MintMe',
       subTitle: 'Stake your $BONE / MintMe LP tokens and get $BONE rewards!',
       BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
       MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
       poolId: 0,
       poolType: 'Fixed',
       active: 'Yes',
       fee: '0.5',
       lpTokenAddress: '0x21D897515b2C4393F7a23BBa210b271D13CCdF10',
       imageTokenA: 'https://dogswap.xyz/images/coins/bone.png',
       imageTokenB: 'https://dogswap.xyz/images/coins/mintme.png',
    },
    {
        title: '$BONE / DogSwap',
        subTitle: 'Stake your $BONE / DogSwap LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 6,
        poolType: 'Fixed',
        active: 'Yes',
        fee: '0.5',
        lpTokenAddress: '0x07Da7DA47b3C71a023d194ff623ab3a737c46393',
        imageTokenA: 'https://dogswap.xyz/images/coins/bone.png',
        imageTokenB: 'https://dogswap.xyz/images/coins/dogswap.png',
    },
    {
        title: '1000x / MINTME',
        subTitle: 'Stake your 1000x / MINTM LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 7,
        poolType: 'Community',
        active: 'Yes',
        fee: '0.5',
        lpTokenAddress: '0x34D99393593245F3268ceAcf35a17407C49c4D59',
        imageTokenA: 'https://dogswap.xyz/images/coins/1000x.png',
        imageTokenB: 'https://dogswap.xyz/images/coins/mintme.png',
    },
    {
        title: '1000x / $BONE',
        subTitle: 'Stake your 1000x / $BONE LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 8,
        poolType: 'Community',
        active: 'Yes',
        fee: '1',
        lpTokenAddress: '0x9763E377ce4E6767F7760D9E1FC7E3c2afBc9Cfb',
        imageTokenA: 'https://dogswap.xyz/images/coins/1000x.png',
        imageTokenB: 'https://dogswap.xyz/images/coins/bone.png',
    },
    {
        title: '1000x / DogSwap',
        subTitle: 'Stake your 1000x / DogSwap LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 9,
        poolType: 'Community',
        active: 'Yes',
        fee: '0.5',
        lpTokenAddress: '0x0cC0D3382fC2826E18606C968842A91e5C52e2b3',
        imageTokenA: 'https://dogswap.xyz/images/coins/1000x.png',
        imageTokenB: 'https://dogswap.xyz/images/coins/dogswap.png',
    },
    {
        title: '$BONE / XatteR',
        subTitle: 'Stake your $BONE / XatteR LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 13,
        poolType: 'Weekly',
        active: 'Yes',
        fee: '2.5',
        lpTokenAddress: '0x32D8Da81B7b4a562b0852ed6823BE8b2CCFa6495',
        imageTokenA: 'https://dogswap.xyz/images/coins/bone.png',
        imageTokenB: 'https://www.mintme.com/media/cache/avatar_large/uploads/images/05dd4214-dc43-11eb-89a0-78e3b517e592.jpeg',
    },
    {
        title: 'MintMe / XatteR',
        subTitle: 'Stake your MintMe / XatteR LP tokens and get $BONE rewards!',
        BONE_TOKEN_ADDRESS: '0x9D8dd79F2d4ba9E1C3820d7659A5F5D2FA1C22eF',
        MASTER_CHEF_ADDRESS: '0x4f79af8335d41A98386f09d79D19Ab1552d0b925',
        poolId: 10,
        poolType: 'Weekly',
        active: 'Yes',
        fee: '2.5',
        lpTokenAddress: '0x1d2a64b660E572ce653f35Ce5A9A655D05ae9fd0',
        imageTokenA: 'https://dogswap.xyz/images/coins/mintme.png',
        imageTokenB: 'https://www.mintme.com/media/cache/avatar_large/uploads/images/05dd4214-dc43-11eb-89a0-78e3b517e592.jpeg',
    },
  ];